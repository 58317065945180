import React from "react"
import SEO from "../components/seo"
import { Link } from 'gatsby';
import home from "../images/home.jpg"
import chambaStyles from "./chamba.module.scss"
import Card from "../components/card"
import { graphql } from "gatsby"

export default function Chamba({ data }) {
  return (
    <div className={chambaStyles.window}>
      <SEO
        title="Join Our Team"
        image={`https://www.ppdc.mx${home}`}
        description="We are looking for the most skilled people to achieve our ambitious mission"
        url="/chamba"
      />
      <h2>Join our Team</h2>
      <p>
        We are looking for the most skilled people to achieve{" "}
        <b>our ambitious mission</b>
      </p>
      <div className={chambaStyles.container}>
        {data.allAirtable.edges.map(edge => {
          return (
            <div className={chambaStyles.gridItem}>
              <Link
                to={`/chamba/${edge.node.data.slug}`}
                title={`Link a ${edge.node.data.title}`}
                fade
                className={chambaStyles.link}
              >
                <Card
                  img={edge.node.data.imgFile.map(img => img.url)}
                  title={edge.node.data.title}
                  description={edge.node.data.description}
                  slug={edge.node.data.slug}
                />
              </Link>
            </div>
          )
        })}
      </div>
      <h2 id='ourmission'>Our Mission</h2>
      <p>
        <b>How might We</b> transform and boost businesses toward their industry
        leadership through consulting and technological innovation using robust
        and scalable strategies?
      </p>
      <h2>We believe in</h2>
      <ul>
        <li>Innovation</li>
        <li>Causality</li>
        <li>Creativity</li>
        <li>Social Responsibility</li>
        <li>Trust</li>
        <li>Teamwork</li>
      </ul>
      <h2>Our True North</h2>
      <p>
        We see ourselves as an inquiry organization. We need to challenge and
        question ourselves to be able to solve big business problems. We are the
        builders of our own work. We are the owners of the whole team success or
        failure.
      </p>
    </div>
  )
}

export const data = graphql`
  query chambas {
    allAirtable(
      filter: { data: { type: { eq: "job" } } }
      sort: { order: ASC, fields: data___priority }
    ) {
      edges {
        node {
          data {
            imgFile {
              url
            }
            description
            slug
            title
            priority
          }
        }
      }
    }
  }
`
