import React from "react"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import cardStyles from "./card.module.scss"
import { Link } from "gatsby"

export default function MediaCard(props) {
  return (
    <Card className={cardStyles.card}>
      <CardActionArea>
        <CardMedia
          className={cardStyles.media}
          image={props.img}
          title={props.title}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            className={cardStyles.cardTitle}
          >
            {props.title}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={cardStyles.cardText}
          >
            {props.description?.length > 120
              ? `${props.description.slice(0, 120)} . . . .`
              : props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Link
          to={`/rent/${props.slug}`}
          title={`Link a ${props.title}`}
          style={{ textDecoration: "none" }}
        >
          <Button
            size="small"
            color="primary"
            style={{ fontFamily: "Montserrat", textTransform: "none" }}
          >
            Saber más
          </Button>
        </Link>
      </CardActions>
    </Card>
  )
}
